var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "widget-grid"
  }, [_c('div', {
    staticClass: "d-flex flex-column rounded-md bg-lighter px-4 py-3"
  }, [_c('div', {
    staticClass: "mb-1",
    class: {
      'placeholder placeholder-inline': _vm.loading
    }
  }, [_c('span', {
    staticClass: "font-size-h2 mr-2"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.data.totalConsumption, 2, 2)))]), _c('span', {
    staticClass: "font-size-h5 text-muted font-w600"
  }, [_vm.type !== 'water' ? _c('span', [_vm._v("kWh")]) : _c('span', [_vm._v("m"), _c('sup', [_vm._v("3")])])])]), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "flex-grow-1",
    class: {
      placeholder: _vm.loading
    }
  }, [_c('p', {
    staticClass: "text-muted font-w600 mb-0"
  }, [_vm._v("Total " + _vm._s(_vm.type !== 'solar' ? 'Consumption' : 'Generation'))]), _c('p', {
    staticClass: "text-muted mb-0"
  }, [_vm._v(_vm._s(_vm.startDate.format('Do MMM YYYY')) + " to " + _vm._s(_vm.endDate.format('Do MMM YYYY')))])]), _vm._m(0)])]), _c('div', {
    staticClass: "d-flex flex-column rounded-md bg-lighter px-4 py-3"
  }, [_c('div', {
    staticClass: "mb-1",
    class: {
      'placeholder placeholder-inline': _vm.loading
    }
  }, [_c('span', {
    staticClass: "font-size-h2 mr-2"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.maxDemand, 2)))]), _c('span', {
    staticClass: "font-size-h5 text-muted font-w600"
  }, [_vm.type !== 'water' ? _c('span', [_vm._v("kW")]) : _c('span', [_vm._v("m"), _c('sup', [_vm._v("3")])])])]), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "flex-grow-1",
    class: {
      placeholder: _vm.loading
    }
  }, [_c('p', {
    staticClass: "text-muted font-w600 mb-0"
  }, [_vm._v("Max. " + _vm._s(_vm.type !== 'solar' ? ' Demand' : ' Daily Generation'))]), _c('p', {
    staticClass: "text-muted mb-0"
  }, [_vm._v(_vm._s(_vm.startDate.format('Do MMM YYYY')) + " to " + _vm._s(_vm.endDate.format('Do MMM YYYY')))])]), _vm._m(1)])]), _c('div', {
    staticClass: "d-flex flex-column rounded-md bg-lighter px-4 py-3"
  }, [_c('div', {
    staticClass: "mb-1",
    class: {
      'placeholder placeholder-inline': _vm.loading
    }
  }, [_c('span', {
    staticClass: "font-size-h2 mr-2"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.minDemand, 2)))]), _c('span', {
    staticClass: "font-size-h5 text-muted font-w600"
  }, [_vm.type !== 'water' ? _c('span', [_vm._v("kW")]) : _c('span', [_vm._v("m"), _c('sup', [_vm._v("3")])])])]), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "flex-grow-1",
    class: {
      placeholder: _vm.loading
    }
  }, [_c('p', {
    staticClass: "text-muted font-w600 mb-0"
  }, [_vm._v("Min. " + _vm._s(_vm.type !== 'solar' ? ' Demand' : ' Daily Generation'))]), _c('p', {
    staticClass: "text-muted mb-0"
  }, [_vm._v(_vm._s(_vm.startDate.format('Do MMM YYYY')) + " to " + _vm._s(_vm.endDate.format('Do MMM YYYY')))])]), _vm._m(2)])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-2 pl-2"
  }, [_c('i', {
    staticClass: "fad fa-meter fa-2x"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-2 pl-2"
  }, [_c('i', {
    staticClass: "fad fa-arrows-up-to-line fa-2x"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-2 pl-2"
  }, [_c('i', {
    staticClass: "fad fa-arrows-down-to-line fa-2x"
  })]);

}]

export { render, staticRenderFns }